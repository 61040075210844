$(function () {
  if ($("form.child-registration-form").length) {
    let current_locale = $("form.child-registration-form").data("locale")
    let firstNameErrorElement = $("span#child-registration-first-name-error")
    let usernameErrorElement = $("span#child-registration-username-error")

    if ($('input[name="child_registration[account_age]"]').length) {
      $('input[name="child_registration[account_age]"]').on("change", function (e) {
        let accountAge = e.target.value
        $(".child-course-field").toggle(accountAge === "0")
        $(".adolescent-course-field").toggle(accountAge === "1")
        $(".adult-course-field").toggle(accountAge === "2")
        $(".email-field").toggle(accountAge !== "0")
      })
      $('input[name="child_registration[account_age]"]:checked').trigger("change")
    }

    if ($("input#child_registration_first_name").length) {
      let checktimeout = null
      $("input#child_registration_first_name").on("keyup", function (e) {
        var firstName = e.target.value
        clearTimeout(checktimeout)
        checktimeout = setTimeout(
          function () {
            if (firstName.length > 3) {
              checkFirstName(firstName)
            }
          },
          200,
          firstName
        )
      })
      function checkFirstName(firstName) {
        var formData = { firstname: firstName, locale: current_locale }
        $.ajax({
          url: "/api/v1/first_name",
          type: "POST",
          data: { form_params: formData },
          success: function (data, textStatus, jqXHR) {
            console.log(data)
            console.log(textStatus)
            firstNameErrorElement.html("")
          },
          error: function (jqXHR, textStatus, errorThrown) {
            firstNameErrorElement.html(jqXHR.responseJSON.message)
          },
        })
      }
    }
    if ($("input#child_registration_username").length) {
      let checktimeoutusername = null
      $("input#child_registration_username").on("keyup", function (e) {
        var username = e.target.value
        clearTimeout(checktimeoutusername)
        checktimeoutusername = setTimeout(
          function () {
            if (username.length > 3) {
              checkUsername(username)
            }
          },
          200,
          username
        )
      })
      function checkUsername(username) {
        var formData = { username: username, locale: current_locale }
        $("div.error-group > span.error").hide()
        $.ajax({
          url: "/api/v1/username",
          type: "POST",
          data: { form_params: formData },
          success: function (data, textStatus, jqXHR) {
            usernameErrorElement.html("")
          },
          error: function (jqXHR, textStatus, errorThrown) {
            usernameErrorElement.html(jqXHR.responseJSON.message)
          },
        })
      }
    }
  }
})
